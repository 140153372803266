<template>
<div class=" mx-auto">
        <div>
            <Loading :isLoading= "this.isLoading" />
            <div>
                <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <div class="text-center">
                                <p class="title">
                                  Datos de ahorro</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <div class="text-center">
                                <h4 class="text-base lg:text-base xl:text-base mt-5 text-center">Paso 5 de 10</h4>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <div class="text-center">
                                <p class="description">
                                    Selecciona cuanto deseas ahorrar de forma quincenal. El importe Mínimo quincenal es de $50.00 y el importe Máximo $500.00 (solo puede ahorrar en Múltiplos de $50.00) 
                                </p>
                            </div>
                            <div class="text-center">
                                <!-- <p v-if="errors.length">
                                <ul>
                                    <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                                </ul>
                                </p> -->
                                <br>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                      <form @submit="Next" class="" style="text-align: left;">
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                          <div class="row mycontent-left">
                              <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-4 px-8 pt-6 pb-8 mb-4">
                                <div>
                                  <div class="my-5">
                                      <label style="text-align: left;" class="label-input">Importe quincenal de ahorro:</label>
                                          <div class="input-group" style="width:100%;">
                                              <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="amount">
                                                  <option>50.00</option>
                                                  <option>100.00</option>
                                                  <option>150.00</option>
                                                  <option>200.00</option>
                                                  <option>250.00</option>
                                                  <option>300.00</option>
                                                  <option>350.00</option>
                                                  <option>400.00</option>
                                                  <option>450.00</option>
                                                  <option>500.00</option>
                                              </select>
                                          </div>
                                      </div>

                                      <div class="my-5" v-if='false'>
                                          <label style="text-align:left;" class="label-input">Plazo:</label>
                                          <div class="input-group">
                                              <input type="radio" value="0" name="rbPlazo" v-model="rdbPlazo" v-on:click="CollapsePlazo()" required /> 
                                              <label for="" class="label-input-radio" style="margin-left:5px;">Indefinido</label>
                                              <input type="radio" value="1" name="rbPlazo" v-model="rdbPlazo" v-on:click="CollapsePlazo()" style="margin-left:10px"/> 
                                              <label for="" class="label-input-radio" style="margin-left:5px;">Otro</label>
                                          </div>
                                      </div>

                                      <div class="my-5" v-if="rdbPlazo == '1'" id="divPlazoAhorro">
                                          <label style="text-align: left;" class="label-input">Ingresa el plazo de ahorro:</label>
                                          <div class="input-group" style="width:100%;">
                                              <input type="number" class="form-control inputs-form"
                                              min="1" maxlength="2" pattern="[0-9]" id="term" oninput="validity.valid||(value='');" v-model="term" required>
                                              <span tabIndex="-1" class="input-group-addon" style="background-color:#ffffff; height:33.5px;">Años</span>
                                          </div>
                                      </div>
                                      <!-- <h4 style="text-align:center;">Paso 5 de 7</h4> -->
                                      <br>
                                      <br>
                              </div>
                              <div>
                                <div class="my-5">
                                  <label style="text-align: left;" class="label-input">Método de descuento</label>
                                    <div class="input-group" style="width:100%">
                                      <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="discountMethod" v-on:change="changeDiscountMethod(discountMethod)">
                                          <!--<option v-if="!(employmentStatus == 'ESTUDIANTE' || employmentStatus == 'JUBILADO') && businessName == '' && principalOccupation == 'Empleado'" value="NÓMINA">NÓMINA</option>-->
                                          <option v-if="showSelectionNomina()" value="NÓMINA">NÓMINA</option>
                                          <option value="DOMICILIACIÓN">DOMICILIACIÓN</option>
                                      </select>
                                    </div>
                                </div>
                                <div v-if="discountMethod == 'DOMICILIACIÓN'">
                                  <div class="my-5">
                                    <label style="text-align: left;" class="label-input">Tipo de cuenta:</label>
                                    <div class="input-group">
                                        <input type="radio" value="cuenta clabe" id="cb" name="rbTipo" v-model="keyType" v-on:change="changeKeyType()" required />
                                        <label for="cb" class="label-input-radio">Cuenta Clabe</label>
                                        <input type="radio" value="numero de tarjeta" id="nt" name="rbTipo" v-model="keyType" v-on:change="changeKeyType()" style="margin-left:10px"/> 
                                        <label for="nt" class="label-input-radio">Número de tarjeta</label>
                                    </div>
                                  </div>
                                  <div class="my-5">
                                  <label class="label-input">Banco</label>
                                    <div class="input-group" style="width:100%">
                                      <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="bank">
                                          <option :value="item.name" v-for="item in list_banks" :key="item.id">{{item.name}}</option>
                                      </select>
                                    </div>
                                    <div class="text-center" v-if="bank != ''"><label style="margin-top:5px;" class="label-input">{{bank}}</label></div>
                                </div>
                                  <div class="my-5" v-if="keyType == 'cuenta clabe'">
                                    <label class="label-input">Cuenta Clabe</label>
                                      <div class="input-group" style="width:100%;">
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-maska="'##################'" min="0" oninput="validity.valid||(value='');"
                                        v-model="keyAccount" required>
                                      </div>
                                  </div>
                                  <div class="my-5" v-if="keyType == 'numero de tarjeta'">
                                    <label class="label-input">Número de Tarjeta</label>
                                      <div class="input-group" style="width:100%;">
                                        <input v-if="this.bank != 'AMERICAN EXPRESS'" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" onKeyPress="if(this.value.length==19) return false;" min="0"
                                          v-model="cardNumber" id="divCardNumber" v-maska="'####-####-####-####'" @keyup="validateCardNumber()" required>
                                        <input v-if="this.bank == 'AMERICAN EXPRESS'" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" onKeyPress="if(this.value.length==19) return false;" min="0"
                                          v-model="cardNumber" id="divCardNumber" v-maska="'####-######-#####'" @keyup="validateCardNumber()" required>
                                      </div>
                                  </div>
                                  <div class="grid grid-cols-2 gap-4" v-if="keyType == 'numero de tarjeta'">
                                    <div class="col-span-2">
                                      <label class="label-input">Fecha de expiración:</label>
                                    </div>
                                    <div class="">
                                      <select v-model="expirationMonth" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" required>
                                        <option value="" selected="true" disabled="disabled">MM</option>
                                        <option :value="month" v-for="(month, i) in list_months" :key="i">{{month}}</option>
                                      </select>
                                    </div>
                                    <div class="">
                                        <div class="input-group" style="width:100%;">
                                          <select v-model="expirationYear" @change="getMonthsCard()" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" required>
                                            <option value="" selected="true" disabled="disabled">AA</option>
                                            <option :value="year" v-for="(year, i) in list_years" :key="i">{{year}}</option>
                                          </select>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="row row-start-1 md:row-auto">
                            <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                                <div class="row">
                                    <div class="text-center">
                                        <img src="../../assets/Cuenta/icon-ahorro.svg" alt="Icon_ahorro" class="mx-auto img-responsive" height="200px" width="200px"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div style="display:flex;justify-content:center;margin-top:50px;">
                        <div>
                          <button class="next_button" type="submit">Siguiente</button>
                        </div>
                      </div>
                      <div style="display:flex;justify-content:center;margin-top:10px;">
                        <div>
                            <button class="back_button" v-on:click="goToBack()" type="button">Atrás</button>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
        </div>
    <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest, updateOnlyStatus } from "@/api/user";
import {  getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      rdbPlazo: "0",
      amount: "0",
      term: "0",
      member_id: "",
      registerExist: false,
      isLoading: false,
      ccapi: process.env.VUE_APP_CCAPI,
      multiApi: process.env.VUE_APP_MULTIAPI,
      admission_request_id:null,
      user_id:null,
      admission_status:null,
      discountMethod: "",
      keyType: "",
      keyAccount: "",
      cardNumber: "",
      expirationYear: "",
      expirationMonth: "",
      clientClassification: "",
      list_banks: [],
      list_years: [],
      list_months: [],
      bank: "",
      paymentFrecuency: "quincenal",
      employmentStatus: "",
      businessName: "",
      principalOccupation: "",
      nameWork: ""
    };
  },
  name: "Ahorro", //CambiarContraseña
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  async mounted() {},
  async beforeMount() {
    await this.loadInfo();
    await this.getBanks();
    this.getYearsCard();
    this.getMonthsCard();
  },
  methods: {
    getYearsCard(){
      var years = [];
      var startYear = new Date().getFullYear()-1;
      var lastYear = new Date().getFullYear() + 5;
      
      while (parseInt(startYear) < parseInt(lastYear) ) {
          startYear++
          var year = startYear
          years.push(parseInt(year.toString().substring(2, 4)));
      }
      this.list_years = years
      return years;
    },
    getMonthsCard() {
      this.list_months = [];
      this.expirationMonth = "";
      if(Number(new Date().getFullYear().toString().substr(-2)) == Number(this.expirationYear)){
        for (var i = (new Date().getMonth()+2); i <= 12; i++) {
          if(i < 10){
            this.list_months.push(`0${i}`);
          }else{
            this.list_months.push(`${i}`);
          }
        }
      }else{
        this.list_months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      }
    },
    getLastMonthsYear(){
      var months = [];
      var startMonth = 0
      if((new Date().getFullYear()).toString().substring(2, 4) == this.expirationYear){
        startMonth = new Date().getMonth();
      }
      var lastMonth = 12
      
      while (parseInt(startMonth) < parseInt(lastMonth) ) {
        startMonth++
        var monthSelect = ""
        if(startMonth < 10){ monthSelect = "0"+startMonth }else{ monthSelect = startMonth }
        months.push(monthSelect.toString());
      }
      return months;
    },
    validateCardNumber(){
      var input = document.getElementById('divCardNumber');
      input.oninvalid = input.setCustomValidity('');
      if(this.bank == "AMERICAN EXPRESS" && input.value.length < 17){
        input.oninvalid = input.setCustomValidity('Debe ingresar los 15 dígitos de la tarjeta');
      }
      if(this.bank != "AMERICAN EXPRESS" && input.value.length < 19){
        input.oninvalid = input.setCustomValidity('Debe ingresar los 16 dígitos de la tarjeta');
      }
    },
    Logout() {
      sessionStorage.clear();
      // window.location.href = "/";
      this.$router.push({path:"/"});
    },
    CollapsePlazo() {
      if (this.rdbPlazo == 0) {
        // document.getElementById("divPlazoAhorro").style.visibility = "visible"
        this.term = 1;
      } else {
        // document.getElementById("divPlazoAhorro").style.visibility = "collapse"
        this.term = 0;
      }
    },
    async getBanks() {
      await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_all_banks`, {
        params:{
          token_auth: sessionStorage.getItem("login")
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        this.list_banks = response.data.response.lista_bancos;
      }).catch(error => {
      });
    },
    async goToBack() {
      await updateOnlyStatus(sessionStorage.getItem("login"), this.admission_request_id, "update_laboral_info").then(() => {
        this.$router.push({path:"/cuenta/informacion-laboral-cliente-regular"});
      });
    },
    RegisterSaving(e) {
      e.preventDefault;
    },
    changeDiscountMethod() {
      this.keyType = "";
      this.keyAccount = "";
      this.cardNumber = "";
      this.expirationMonth = "";
      this.expirationYear = "";
      this.bank = "";
    },
    changeKeyType() {
      this.keyAccount = "";
      this.cardNumber = "";
      this.expirationMonth = "";
      this.expirationYear = "";
      this.bank = "";
    },
    formatMoney(value) {
      const options2 = { style: "currency", currency: "MXN" };
      const formatter = new Intl.NumberFormat("es-MX", options2);
      var resultado = formatter.format(value);
      resultado = resultado.replace("$", "");
      return resultado;
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.user_id = auth["id"];
      await this.searchPersonalData();
    },
    async searchPersonalData() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if(Object.entries(response).length != 0)
      {
        await this.getDataAdmissionUser(response);
      }else {
        await this.getAdmissionRequest();
      }
    },
    async getDataAdmissionUser(response) {
      if(Object.entries(response).length != 0) {
        this.clientClassification = response.data.response.admission_request.admission_information.client_classification;
        this.admission_request_id = response.data.response.admission_request.id;
        this.admission_status = response.data.response.admission_request.status;
        this.businessName = response.data.response.admission_request.admission_information.work_info_attributes.business_name;
        this.principalOccupation = response.data.response.admission_request.admission_information.work_info_attributes.principal_occupation;
        this.nameWork = response.data.response.admission_request.admission_information.workplace_attributes.name;

        if(response.data.response.admission_request.admission_information.work_info_attributes.laboral_status == "JUBILADO" ||
        response.data.response.admission_request.admission_information.work_info_attributes.laboral_status == "ESTUDIANTE"){
          this.discountMethod = "DOMICILIACIÓN"
        }

        this.employmentStatus = response.data.response.admission_request.admission_information.work_info_attributes.laboral_status;
        
        if(response.data.response.admission_request.admission_information.saving_registration_attributes.status != ""){
          
          this.term = response.data.response.admission_request.admission_information.saving_registration_attributes.number_of_contribution;
          this.rdbPlazo = this.term != 0 ? 1 : this.term;
          this.discountMethod = response.data.response.admission_request.admission_information.saving_registration_attributes.payment_method;
          
          if (this.discountMethod == "DOMICILIACIÓN") {
            this.amount = response.data.response.admission_request.admission_information.saving_registration_attributes.amount/2+".00"
            let bank_account_attributes_array = this.convertListToArray(response.data.response.admission_request.admission_information.bank_account_attributes);
            this.bank = bank_account_attributes_array[0].bank_name;
            this.keyType = bank_account_attributes_array[0].account_type
            this.cardNumber = this.keyType == "numero de tarjeta" ? this.includeDashesCardNumber(bank_account_attributes_array[0].account_number, this.bank) : ""
            this.keyAccount = this.keyType == "cuenta clabe" ? bank_account_attributes_array[0].account_number : ""
            this.expirationYear = bank_account_attributes_array[0].expiration_year;
            this.expirationMonth = bank_account_attributes_array[0].expiration_month;
          } else {
            this.amount = response.data.response.admission_request.admission_information.saving_registration_attributes.amount;
            this.keyType = ""
            this.cardNumber = ""
            this.keyAccount = ""
            this.expirationYear = ""
            this.expirationMonth = ""
            this.bank = ""
          }
        }
      }
    },
    convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    },
    async Next(e) {
      e.preventDefault();
      this.isLoading = true;
      moment.locale("es");

      if (this.discountMethod == "DOMICILIACIÓN") {
        if (this.keyType == "cuenta clabe") {
          if (this.keyAccount.length < 18) {
            Swal.fire({
              title: "Aviso",
              text:
                "La Cuenta Clave debe contener 18 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          }
        }
        if (this.keyType == "numero de tarjeta") {
          if (this.bank == "AMERICAN EXPRESS" && this.cardNumber.replaceAll("-","").length < 15) {
            Swal.fire({
              title: "Aviso",
              text:
                "El número de Tarjeta debe contener 15 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          } else if (this.bank != "AMERICAN EXPRESS" && this.cardNumber.replaceAll("-","").length < 16) {
            Swal.fire({
              title: "Aviso",
              text:
                "El número de Tarjeta debe contener 16 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          } else {
            if (this.expirationMonth <= 0) {
              Swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Mes de Expiración de la Tarjeta debe ser mayor a 0",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (this.expirationYear.length < 2) {
              Swal.fire({
                title: "Aviso",
                text:
                  "El Año de Expiración debe contener 2 dígitos",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (this.expirationMonth.length < 2) {
              Swal.fire({
                title: "Aviso",
                text:
                  "El Mes de Expiración debe contener 2 dígitos",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (this.expirationMonth > 12) {
              Swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Mes de Expiración de la Tarjeta no puede ser mayor a 12",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            
            if (!this.getLastMonthsYear().includes(this.expirationMonth)) {
              Swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "La tarjeta introducida ya ha expirado",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
          }
        }
      }
      
      var infoJson = this.buildJson();

      if(this.admissionStatusIsValidToSave(this.admission_status) )
      {
        await this.saveRequest(infoJson);
      }

      this.isLoading = false;
      this.$router.push({ path: '/cuenta/beneficiarios' });
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    buildJson(admission_status = "saving_info") {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if (this.discountMethod == "DOMICILIACIÓN") {
        json.data.response.admission_request.admission_information.saving_registration_attributes.amount = this.amount*2;
        json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency = "mensual";
      }else{
        json.data.response.admission_request.admission_information.saving_registration_attributes.amount = this.amount;
        json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency = this.paymentFrecuency;
      }

      json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method = this.discountMethod;
      
      json.data.response.admission_request.admission_information.saving_registration_attributes.number_of_contribution = this.term;
      
      json.data.response.admission_request.admission_information.saving_registration_attributes.status = "activo";
      json.data.response.admission_request.admission_information.saving_registration_attributes.date_of_start = moment().format("YYYY-MM-DD");
      json.data.response.admission_request.admission_information.saving_registration_attributes.date_of_end = this.term == 0 ? moment().format("YYYY-MM-DD") : moment().add(this.term, 'y').format("YYYY-MM-DD");
      

      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json);

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: admission_status,
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "autorizo_descuento_aceptado",
        "video_completo",
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    },
    async getAdmissionRequest(){
      let _json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      if( _json != null ){
      
        if(_json.data.response.admission_request.admission_information.work_info_attributes.laboral_status == "JUBILADO"){
          this.discountMethod = "DOMICILIACIÓN"
        }

        this.employmentStatus = _json.data.response.admission_request.admission_information.work_info_attributes.laboral_status;
        this.businessName = _json.data.response.admission_request.admission_information.work_info_attributes.business_name;
        this.principalOccupation = _json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation;

        this.admission_request_id = _json.data.response.admission_request.id;
        this.admission_status = _json.data.response.admission_request.status;  //se obtiene el estatus de la solicitud
        if(_json.data.response.admission_request.admission_information.saving_registration_attributes.status != ""){
          this.term = _json.data.response.admission_request.admission_information.saving_registration_attributes.number_of_contribution;
          this.rdbPlazo = this.term != 0 ? 1 : this.term;
          this.discountMethod = _json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method;
          
          if (this.discountMethod == "DOMICILIACIÓN") {
            this.amount = response.data.response.admission_request.admission_information.saving_registration_attributes.amount/2+".00"
            let bank_account_attributes_array = this.convertListToArray(_json.data.response.admission_request.admission_information.bank_account_attributes);
            this.bank = bank_account_attributes_array[0].bank_name;
            this.keyType = bank_account_attributes_array[0].account_type
            this.cardNumber = this.keyType == "numero de tarjeta" ? this.includeDashesCardNumber(bank_account_attributes_array[0].account_number, this.bank) : ""
            this.keyAccount = this.keyType == "cuenta clabe" ? bank_account_attributes_array[0].account_number : ""
            this.expirationYear = bank_account_attributes_array[0].expiration_year;
            this.expirationMonth = bank_account_attributes_array[0].expiration_month;
          } else {
            this.amount = response.data.response.admission_request.admission_information.saving_registration_attributes.amount
            this.keyType = ""
            this.cardNumber = ""
            this.keyAccount = ""
            this.expirationYear = ""
            this.expirationMonth = ""
            this.bank = ""
          }
        }
      }
      else{
        Swal.fire({
          title: "Aviso",
          text:
            "Se presentó un error. No fue posible obtener los datos de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        // window.location.href = "/cuenta/registro-datos-personales";
        this.$router.push({path:"/cuenta/registro-datos-personales"});
        //this.$router.push({name: "Login"});
      }
    },
    async saveRequest(jsonData){
      this.isLoading=true;
      jsonData.token_auth = sessionStorage.getItem("login");
      await saveRequest(
        jsonData
      )
        .then((response ) => {
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        })
        .catch((error) => {
          console.log(
            "Se presento un error al guardar la información personal: ",
            error
          );
        }).finally( () => {this.isLoading=false})
    },
    buildJson() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      if (this.discountMethod == "DOMICILIACIÓN") {
        json.data.response.admission_request.admission_information.saving_registration_attributes.amount = this.amount*2;
        json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency = "mensual";
      }else{
        json.data.response.admission_request.admission_information.saving_registration_attributes.amount = this.amount;
        json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency = this.paymentFrecuency;
      }

      json.data.response.admission_request.admission_information.saving_registration_attributes.number_of_contribution = this.term;
      json.data.response.admission_request.admission_information.saving_registration_attributes.status = "activo";
      json.data.response.admission_request.admission_information.saving_registration_attributes.date_of_start = moment().format("YYYY-MM-DD");
      json.data.response.admission_request.admission_information.saving_registration_attributes.date_of_end = this.term == 0 ? moment().format("YYYY-MM-DD") : moment().add(this.term, 'y').format("YYYY-MM-DD");
      json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method = this.discountMethod;

      let object_bank_account_attributes = {}
      if (this.discountMethod == "DOMICILIACIÓN") {
        object_bank_account_attributes = {
          bank_name : this.bank,
          account_number : this.keyAccount != "" ? this.keyAccount : this.cardNumber.replaceAll("-",""),
          expiration_month : this.keyType == "numero de tarjeta" ? this.expirationMonth : "",
          expiration_year : this.keyType == "numero de tarjeta" ? this.expirationYear : "",
          account_type: this.keyType
        }
      } else {
        object_bank_account_attributes = {
          bank_name : "",
          account_number : "",
          expiration_month : "",
          expiration_year : "",
          account_type: ""
        }
      }

      let bank_account_attributes_array = [];
      bank_account_attributes_array.push(object_bank_account_attributes);
      json.data.response.admission_request.admission_information.bank_account_attributes = Object.assign({}, bank_account_attributes_array);

      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json)

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: "saving_info",
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    includeDashesCardNumber(cardNumber, bank){
      if(cardNumber.includes("-")){
        return cardNumber;
      }
      
      if(bank == "AMERICAN EXPRESS"){
        return cardNumber.substring(0,4) +"-"+ cardNumber.substring(4,10) +"-"+ cardNumber.substring(10,15);
      }
      return cardNumber.substring(0,4) +"-"+ cardNumber.substring(4,8) +"-"+ cardNumber.substring(8,12) +"-"+ cardNumber.substring(12,17);
    },
    showSelectionNomina(){
      if(this.principalOccupation == "Trabajador de la educacion"){
        return true;
      }
      if(this.principalOccupation == "Empleado" && ["GRUPO CABSA","CONSULTORIA ASOCIADA EN BENEFICIOS, AGENTE DE SEGUROS Y DE FIANZAS S.A. DE C.V."].includes(this.nameWork)){
        return true;
      }
      return false;
    }
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: auto;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#button_popups{
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}
</style>